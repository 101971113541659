<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="addItem">新增大图</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #state>
        <el-table-column label="状态" align="center">
          <template #default="scoped">
            <span>{{ scoped.row.state === 1 ? '上架' : '下架' }}</span>
          </template>
        </el-table-column>
      </template>
      <template #name>
        <el-table-column label="商品名称" align="center">
          <template #default="scoped">
            <span>{{ scoped.row.goods.name }}</span>
          </template>
        </el-table-column>
      </template>
      <template #url>
        <el-table-column label="banner" align="center">
          <template #default="scoped">
            <img
              :src="scoped.row.url"
              class="backgroundUrl"
              @click="openImagePreview(scoped.row)"
            />
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="editItem(scoped.row)"
            >
              修改
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="shelvesItem(scoped.row)"
              v-if="scoped.row.state == 0"
            >
              上架轮播图
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="shelvesItem(scoped.row)"
              v-if="scoped.row.state != 0"
            >
              下架轮播图
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="deleteItem(scoped.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="labelVisible"
      :title="title"
      width="50%"
      class="user_dialog"
      :before-close="labelClose"
    >
      <div class="box_dialog" v-if="title == '详情'"></div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="显示排序：">
          <el-input
            v-model="ruleForm.sort"
            placeholder="请输入排序"
            @keyup="numberInput"
          />
        </el-form-item>
        <el-form-item label="banner大图：" prop="url">
          <div class="upload">
            <fileUpload
              v-if="labelVisible"
              @getimglist="getimglist"
              ref="uploadFilesDom"
              :imgUrl="ruleForm.url"
              widthSize="1340"
              heightSize="736"
            />
          </div>
        </el-form-item>
        <el-form-item label="选择商品：" prop="goodsId">
          <el-button type="primary" @click="getGoods">选择商品</el-button>
        </el-form-item>
        <el-form-item
          class="form_date form_date_table"
          v-if="specifyTableData.length > 0"
        >
          <TableView
            :columns="specifyColumns"
            :data="specifyTableData"
            :is-pagination-show="false"
          >
            <template #description>
              <el-table-column label="商品信息" width="240" align="center">
                <template #default="scoped">
                  <div class="desc">
                    <img :src="scoped.row.image" style="width: 50px" />
                    <span>{{ scoped.row.name }}</span>
                  </div>
                </template>
              </el-table-column>
            </template>
            <template #action>
              <el-table-column label="操作" width="100" align="center">
                <template #default="scoped">
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleClickDelete(scoped.row)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </template>
          </TableView>
        </el-form-item>
      </el-form>
      <template #footer v-if="title != '详情'">
        <span class="dialog-footer">
          <el-button type="primary" @click="remarkSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <el-image-viewer
      v-if="goodsImageList.length"
      :url-list="goodsImageList"
      hide-on-click-modal
      @close="handleClose"
    />
    <el-dialog
      v-model="cardsVisible"
      title="选择商品"
      width="800px"
      :before-close="cardsClose"
    >
      <FormView
        :formData="fromDataSpecify"
        :formLabels="formLabelsSpecify"
        :column="formColumnSpecify"
        :labelWidth="labelWidth"
      >
        <el-button type="warning" @click="resetSpecify">重置</el-button>
        <el-button type="primary" @click="searchSpecify">查询</el-button>
      </FormView>
      <el-table
        v-loading="shopLoading"
        :data="shopTableData"
        height="450"
        style="width: 100%"
      >
        <el-table-column label="选择" width="60">
          <!-- <template #header>
            <el-checkbox v-model="selectAll"></el-checkbox>
          </template> -->
          <template #default="{ row }">
            <div class="flex row-center" @click.stop>
              <el-checkbox
                :model-value="selectItem(row)"
                @change="handleSelect($event, row)"
              ></el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品信息" width="380" align="center">
          <template #default="scoped">
            <div class="desc">
              <img :src="scoped.row.image" style="width: 50px" />
              <span>{{ scoped.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="库存" align="center" prop="totalStock">
        </el-table-column>
        <el-table-column label="价格" align="center">
          <template #default="scoped">
            {{ initPrice(scoped.row) }}
          </template>
        </el-table-column>
        <el-table-column label="销售状态" align="center">
          <template #default="scoped">
            {{ scoped.row.state == 0 ? '下架' : '上架' }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px"
        :current-page="shopPagination.current"
        :page-size="shopPagination.size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="shopPagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cardsClose"> 取消 </el-button>
          <el-button type="primary" @click="checkoutSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import fileUpload from '@/components/fileUpload1'
import { reactive, onMounted, toRefs, ref, computed } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  queryCriteria,
  goodsCreate,
  goodsUpdate,
  goodsDel,
  goodsState
} from '@/api/productManagement/productRecommendations'
import { goodsCategoryTree } from '@/api/productManagement/productClassify'
import { queryCriteriaSpecify } from '@/api/productManagement/productList'
const ruleFormRef = ref()

const initData = reactive({
  formLabels: [
    {
      label: 'keyword',
      title: '关键字',
      placeholder: '请输入商品名称'
    }
  ],
  fromData: {
    // 表单数据
    keyword: ''
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { slot: 'name' },
    { slot: 'url' },
    { slot: 'state' },
    { prop: 'sort', label: '排序' },
    { slot: 'action' }
  ],
  ruleForm: {
    id: '',
    name: '',
    goodsId: '',
    sort: '',
    url: ''
  },
  labelVisible: false,
  title: '',
  goodsImageList: [],
  homeJumpUrlList: [],
  // 选择商品弹窗数据
  cardsVisible: false,
  shopLoading: false,
  shopTableData: [],
  shopPagination: {
    current: 1,
    size: 10,
    total: 0
  },
  selectAll: [],
  specifyColumns: [{ slot: 'description' }, { slot: 'action' }],
  changeCurrentData: [],
  specifyTableData: [],
  fromDataSpecify: {
    // 表单数据
    keyword: '',
    goodsCategoryId: ''
  },
  formLabelsSpecify: [
    {
      label: 'goodsCategoryId',
      title: '商品分类',
      placeholder: '请选择',
      type: 'options',
      options: []
    },
    {
      label: 'keyword',
      title: '商品搜索',
      placeholder: '请输入关键字以搜索'
    }
  ],
  formColumnSpecify: 2.5
})

onMounted(() => {
  getDataList()
  getClassifyDataList()
})

// 预览图片
const handleClose = () => {
  goodsImageList.value = []
}

// 图片预览
const openImagePreview = row => {
  goodsImageList.value = [row.url]
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 修改状态
const editItem = row => {
  title.value = '修改'
  ruleForm.value = {
    id: row.id,
    goodsId: row.goodsId,
    sort: row.sort,
    url: row.url
  }
  specifyTableData.value = [row.goods]
  changeCurrentData.value = [row.goods]
  labelVisible.value = true
}

// 新增
const addItem = () => {
  title.value = '新增'
  ruleForm.value = {
    id: '',
    goodsId: '',
    sort: '',
    url: ''
  }
  specifyTableData.value = []
  changeCurrentData.value = []
  labelVisible.value = true
}

// 关闭
const labelClose = () => {
  ruleForm.value = {
    id: '',
    goodsId: '',
    sort: '',
    url: ''
  }
  ruleFormRef.value.resetFields()
  labelVisible.value = false
}

// 图片上传
const getimglist = val => {
  ruleForm.value.url = val
}

// 排序限制
const numberInput = e => {
  e.target.value = e.target.value.replace(/[^\d]/g, '')
}

// 删除
const deleteItem = row => {
  ElMessageBox.confirm('是否确认删除（该操作不可逆），是否继续？', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      goodsDel({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 提交
const remarkSubmit = async () => {
  if (!ruleFormRef.value) return
  if (ruleForm.value.url == '') {
    ElMessage.warning('请上传banner图')
    return false
  }
  if (specifyTableData.value.length == 0) {
    ElMessage.warning('请选择商品')
    return false
  }
  const data = {
    goodsId: specifyTableData.value[0].id,
    sort: ruleForm.value.sort,
    url: ruleForm.value.url
  }
  if (ruleForm.value.id == '') {
    goodsCreate(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        getDataList()
        labelVisible.value = false
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    data.id = ruleForm.value.id
    goodsUpdate(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        getDataList()
        labelVisible.value = false
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

// 上架
const shelvesItem = row => {
  ElMessageBox.confirm(
    '上架大图后，该大图将在小程序展示。是否继续？',
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(() => {
      goodsState({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keyword != '') {
    data.keyword = fromData.value.keyword
  }
  queryCriteria(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 指定商品
// 重置
const resetSpecify = () => {
  fromData.value = {
    // 表单数据
    keyword: '',
    goodsCategoryId: ''
  }
  shopPagination.value.current = 1
  shopPagination.value.size = 10
  getDataListSpecify()
}
// 查询
const searchSpecify = () => {
  shopPagination.value.current = 1
  shopPagination.value.size = 10
  getDataListSpecify()
}

const handleSizeChange = val => {
  shopPagination.value.current = 1
  shopPagination.value.size = val
  getDataListSpecify()
}

const handleCurrentChange = val => {
  shopPagination.value.current = val
  getDataListSpecify()
}

// 获取列表数据
const getDataListSpecify = () => {
  shopLoading.value = true
  const data = {
    current: shopPagination.value.current,
    size: shopPagination.value.size,
    state: 1,
    payType: 1
  }
  if (fromDataSpecify.value.keyword !== '') {
    data.keyword = fromDataSpecify.value.keyword
  }
  if (fromDataSpecify.value.goodsCategoryId !== '') {
    data.goodsCategoryId = fromDataSpecify.value.goodsCategoryId
  }
  queryCriteriaSpecify(data)
    .then(({ data: res }) => {
      shopLoading.value = false
      if (res.code == 200) {
        shopTableData.value = res.data.records
        shopPagination.value.total = Number(res.data.pageInfo.total)
        // changeCurrentData.value = specifyTableData.value
      } else {
        ElMessage.error(res.msg)
        shopTableData.value = []
        shopPagination.value.total = 0
      }
    })
    .catch(() => {
      shopLoading.value = false
    })
}

// 表格全选
const selectAll = computed({
  get: () => {
    if (!changeCurrentData.value) return false
    const ids = changeCurrentData.value.map(item => item.id)
    if (!shopTableData.value.length) {
      return false
    }
    return shopTableData.value.every(item => ids.includes(item.id))
  },
  set: val => {
    if (val) {
      for (let i = 0; i < shopTableData.value.length; i++) {
        const item = shopTableData.value[i]
        const ids = changeCurrentData.value.map(item => item.id)
        if (!ids.includes(item.id) && changeCurrentData.value.length == 0) {
          changeCurrentData.value.push(item)
        }
      }
    } else {
      shopTableData.value.forEach(row => {
        deleteSelectedData(row)
      })
    }
  }
})

// 选择回显
const selectItem = computed(() => {
  return row => {
    if (!changeCurrentData.value) return false
    return changeCurrentData.value.some(item => item.id == row.id)
  }
})

// 单选
const handleSelect = ($event, row) => {
  if ($event && changeCurrentData.value.length == 0) {
    changeCurrentData.value.push(row)
  } else {
    deleteSelectedData(row)
  }
}

// 选中商品确认
const checkoutSubmit = () => {
  specifyTableData.value = []
  if (changeCurrentData.value.length == 0) {
    ElMessage.warning('请选择卡券商品')
    return false
  }
  if (changeCurrentData.value.length <= 1) {
    changeCurrentData.value.forEach(item => {
      specifyTableData.value.push(item)
    })
    cardsVisible.value = false
  } else {
    ElMessage.warning('所选数据超过1条,请重新选择')
  }
}

// 关闭商品弹窗
const cardsClose = () => {
  fromData.value = {
    // 表单数据
    keyword: '',
    goodsCategoryId: ''
  }
  shopPagination.value.current = 1
  shopPagination.value.size = 10
  cardsVisible.value = false
  if (specifyTableData.value.length == 0) {
    changeCurrentData.value = []
  }
  if (changeCurrentData.value.length == 0) {
    specifyTableData.value = []
  }
  const ids = specifyTableData.value.map(item => item.id)
  const cus = changeCurrentData.value.map(item => item.id)
  if (ids.length > cus.length) {
    let item = specifyTableData.value.filter(itemB => !cus.includes(itemB.id))
    changeCurrentData.value.push(...item)
  } else {
    changeCurrentData.value = changeCurrentData.value.filter(itemB =>
      ids.includes(itemB.id)
    )
  }
}
// 选择商品弹窗
const getGoods = () => {
  shopPagination.value.current = 1
  shopPagination.value.size = 10
  getDataListSpecify()
  cardsVisible.value = true
}

// 商品价格
const initPrice = row => {
  let val = `${row.minPrice} ~ ${row.maxPrice}`
  if (row.minPrice == row.maxPrice) {
    val = row.minPrice
  }
  return val
}

// 删除
const deleteSelectedData = row => {
  const index = changeCurrentData.value.findIndex(item => item.id == row.id)
  if (index != -1) {
    changeCurrentData?.value.splice(index, 1)
  }
}

// 获取分类列表数据
const getClassifyDataList = (val, flag) => {
  goodsCategoryTree().then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
      formLabelsSpecify.value[0].options = list
    } else {
      ElMessage.error(res.msg)
      formLabelsSpecify.value[0].options = []
    }
  })
}

// 选中商品删除
const handleClickDelete = row => {
  ElMessageBox.confirm(`是否确认删除当前商品？`, '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      specifyTableData.value = specifyTableData.value.filter(
        item => item.id != row.id
      )
      changeCurrentData.value = changeCurrentData.value.filter(
        item => item.id != row.id
      )
    })
    .catch(() => {})
}

const {
  cardsVisible,
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  ruleForm,
  rules,
  labelVisible,
  title,
  shopLoading,
  shopTableData,
  shopPagination,
  specifyTableData,
  goodsImageList,
  changeCurrentData,
  fromDataSpecify,
  formLabelsSpecify,
  formColumnSpecify,
  specifyColumns
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.writeoff_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  span {
    width: 100px;
    text-align: right;
  }

  .dialog_input {
    width: 50%;
  }

  i {
    color: red;
  }
}

.remark_box {
  align-items: flex-start;
}
.demo-ruleForm {
  width: 50%;
  margin-left: 60px;
  .el-form-item__content {
    width: calc(100% - 100px);
    position: relative;
  }
  .el-input,
  .el-select {
    width: 100%;
  }
}
.tip {
  display: block;
  width: 220px;
  position: absolute;
  top: 5px;
  right: -220px;
}
.upload {
  width: 100%;
}

.backgroundUrl {
  width: 100px;
}
.box_dialog {
  width: 100%;
  height: calc(100% - 60px);
  background: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
}
:deep(.colorcont) {
  position: relative;
  .el-color-picker {
    position: absolute;
    top: 0;
    right: 0;
  }
  .el-input.is-disabled .el-input__wrapper {
    background: transparent;
    // .el-input__inner {
    //   color: #606266;
    //   -webkit-text-fill-color: #606266;
    // }
  }
}
.desc {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  span {
    width: 300px;
    text-align: left;
  }
}
</style>
