import request from '../Axios'

// 列表
export const queryCriteria = data => {
  return request({
    url: '/goods/recommend/query/criteria',
    method: 'post',
    data
  })
}

// 商品新增
export const goodsCreate = data => {
  return request({
    url: '/goods/recommend/create',
    method: 'post',
    data
  })
}

// 商品修改
export const goodsUpdate = data => {
  return request({
    url: '/goods/recommend/update',
    method: 'put',
    data
  })
}

// 商品删除
export const goodsDel= params => {
  return request({
    url: `/goods/recommend/del/${params.id}`,
    method: 'delete'
  })
}

// 商品删除
export const goodsState= params => {
  return request({
    url: `/goods/recommend/change/state/${params.id}`,
    method: 'put'
  })
}
